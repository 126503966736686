<template>
  <div class="container">
    <div class="loginForm">
      <h1 style="color: #fff;">登录</h1>
      <el-form ref="loginForm">
        <el-form-item prop="account">
          <el-input prefix-icon="el-icon-user" v-model="form.account" placeholder="账号" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input type="password" prefix-icon="el-icon-lock" v-model="form.password" placeholder="密码"
                    autocomplete="off"></el-input>
        </el-form-item>

        <!--<el-form-item style="width: 150px; float: left; margin-right: 20px">-->
          <!--<el-input-->
            <!--prefix-icon="el-icon-mobile-phone"-->
            <!--v-model="form.captcha"-->
            <!--placeholder="验证码"-->
            <!--autocomplete="off"-->
          <!--&gt;</el-input>-->
        <!--</el-form-item>-->
        <!--<div style="float: left">-->
          <!--<img  id="captcha" src="/admin/captcha" @click="getCaptcha"/>-->
        <!--</div>-->
        <div style="text-align: center">
          <el-button type="primary" @click="onSubmit" style="width: 100%;">登录</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'login',
    data () {
      return {
        form: {
          account: '',
          password: '',
          captcha: '',
          // key: '',
          // symbol: localStorage.getItem('symbol') || ''
        },
        captchaImage: ''
      }
    },
    created () {
    },
    methods: {
      getCaptcha () {
        document.getElementById('captcha').src = '/admin/captcha?v=' + Math.random()
      },
      onSubmit () {
        this.$http
          .post('/admin/login', this.form)
          .then(res => {
            const token= res.data || ''
            localStorage.setItem('admin_token', token)
            this.$router.replace('/')
          })
          .catch(err => {
            this.$message.error(err.message)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    min-height: 100%;
    width: 100%;
    background-color: #2d3a4b;

    .loginForm {
      width: 300px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      ::v-deep .el-input__icon {
        font-size: 20px;
      }
    }
  }
</style>
